import React from "react";
import AppBar from "@material-ui/core/AppBar";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import ShowChart from "@material-ui/icons/ShowChart";
import Card from "@material-ui/core/Card";
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Link from "@material-ui/core/Link";
// import TextField from "@material-ui/core/TextField";
// import Dialog from "@material-ui/core/Dialog";
// import DialogActions from "@material-ui/core/DialogActions";
// import DialogContent from "@material-ui/core/DialogContent";
// import DialogContentText from "@material-ui/core/DialogContentText";
// import DialogTitle from "@material-ui/core/DialogTitle";
// import InputLabel from "@material-ui/core/InputLabel";
// import Input from "@material-ui/core/Input";
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
// import ListSubheader from "@material-ui/core/ListSubheader";
// import FormControl from "@material-ui/core/FormControl";
// import Select from "@material-ui/core/Select";
// import { Slide } from 'react-slideshow-image';
import FlagOutlinedIcon from '@material-ui/icons/FlagOutlined';
// import { Timeline, IEvent } from 'react-material-timeline';
import { Avatar, Icon } from '@material-ui/core';
// import Paper from '@material-ui/core/Paper';
// import Divider from '@material-ui/core/Divider';
import AppsIcon from '@material-ui/icons/Apps';

// import Paper from "@material-ui/core/Paper";
// import Carousel from 'react-material-ui-carousel';
// import SliderItem from './SliderItem';

// import customCss from './custom.css';
// import content from './content';

import "./styles.css";

import randomColor from 'randomcolor';
import TagCloud from 'react-tag-cloud';
import CloudItem from './CloudItem';



function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {"Copyright © "}
            <Link color="inherit" href="https://material-ui.com/">
                ikan.ai
      </Link>{" "}
            {new Date().getFullYear()}
            {"."}
        </Typography>
    );
}

const useStyles = makeStyles(theme => ({
    paper: {
        padding: theme.spacing(2),
        textAlign: "center",
        color: theme.palette.text.secondary
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 110
    },
    fontselect: {
        color: "white"
    },
    icon: {
        marginRight: theme.spacing(2)
    },
    heroContent: {
        backgroundColor: theme.palette.background.paper,
        // padding: theme.spacing(8, 0, 6)
        // padding: theme.spacing(0, 0, 6)
    },
    heroButtons: {
        marginTop: theme.spacing(4)
    },
    cardGrid: {
        paddingTop: theme.spacing(8),
        paddingBottom: theme.spacing(8)
    },
    card: {
        height: "100%",
        display: "flex",
        flexDirection: "column"
    },
    cardMedia: {
        paddingTop: "56.25%" // 16:9
    },
    cardContent: {
        flexGrow: 1
    },
    footer: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(6)
    },
    large: {
        fontSize: 60,
        fontWeight: 'bold'
    },
    small: {
        opacity: 0.7,
        fontSize: 16
    }    
}));

// const cards = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

const cards = [{
    "title": "Website Development",
    "expert": "5233",
    "price": "$400 - $8000",
    "image": "https://staticfiles3.hellotoby.com/service-image/website-development.jpg!banner-thumbnail-lg",
    "description": "Looking for a professional web developer or a web company? Wanting to get web developer quotes? As businesses shift to the Internet, web design and development are essential for company branding, advertising, and so on. Web or mobile development both involve web programming, layout design, and a lot of professional skills.",
},
{
    "title": "App Development",
    "expert": "7654",
    "price": "$1000 - $20000",
    "image": "https://staticfiles3.hellotoby.com/service-image/auditing.jpg!banner-thumbnail-lg",
    "description": "Mobile devices and smart phones have became an inevitable part of our daily lives. Almost everything like shopping, delivery, stock trading, transportation scheduling and ticketing can be solved with the touch of your finger on a screen. Statistical study shows that web browser users are declining and transitioned to using mobile app programs (mobile application).",
},
{
    "title": "Website Design",
    "expert": "2000",
    "price": "$700 - $10000",
    "image": "https://staticfiles3.hellotoby.com/service-image/website-design.jpg!banner-thumbnail-lg",
    "description": "A good website design can catch the attention of your potential customers on the Internet. Web page design involves not only design itself, but it also requires professional programming skills to make a good layout. Looking for a web design company or web designer in Hong Kong?",
},
{
    "title": "UI Design",
    "price": "$1000 - $9000",
    "expert": "766",
    "image": "https://staticfiles3.hellotoby.com/service-image/Business-Design.jpg!banner-thumbnail-lg",
    "description": "A good UI design can catch the attention of your potential customers. Compare and Hire a curated UI designer.",
},
// {
//     "title": "3D Modeling",
//     "price": "$10000 - $90000",
//     "expert": "201",
//     "image": "https://staticfiles3.hellotoby.com/service-image/logo-trademark.jpg!banner-thumbnail-lg",
//     "description": "No Agency Fee. Compare and Hire a curated 3D Modeling Company. Save you 99% of time.",
// },
// {
//     "title": "Animator",
//     "price": "$10000 - $90000",
//     "expert": "300",
//     "image": "https://staticfiles3.hellotoby.com/service-image/Advertising-Design.jpg!banner-thumbnail-lg",
//     "description": "Compare and Hire a curated Animator. Save you 99% of time.",
// },
// {
//     "title": "DevOps & Security",
//     "price": "$10000 - $90000",
//     "expert": "100",
//     "image": "https://staticfiles3.hellotoby.com/service-image/computer-network-installation.jpg!banner-thumbnail-lg",
//     "description": "DevOps is a set of practices that automates the processes between software development and IT teams, in order that they can build, test, and release software faster and more reliably. The concept of DevOps is founded on building a culture of collaboration between teams that historically functioned in relative siloes.",
// },
// {
//     "title": "IT Security Audit",
//     "price": "$10000 - $90000",
//     "expert": "75",
//     "image": "https://cdn.lynda.com/course/562257/562257-636903240270301498-16x9.jpg",
//     "description": "A security audit is the high- level description of the many ways organizations can test and assess their overall security posture, including cybersecurity.You might employ more than one type of security audit to achieve your desired results and meet your business objectives.",
// },
// {
//     "title": "Computer Network Installation",
//     "price": "$10000 - $90000",
//     "expert": "156",
//     "image": "https://upload.wikimedia.org/wikipedia/commons/d/db/Ethernet_LAN.svg",
//     "description": "Receive and compare quotes from different Computer Network Installation Professional for free",
// },
    // {
    //     "title": "",
    //     "image": "",
    //     "description": "",
    // },
];

export default function App() {
    const classes = useStyles();

    const [open, setOpen] = React.useState(false);
    const [openLogin, setOpenLogin] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [selectedLanguage, setSelectedLanguage] = React.useState('English');


    const handleClickMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = (lang = null) => {
        if (lang !== null) {
            setSelectedLanguage(lang);
        }
        setAnchorEl(null);
    };

    const handleClickOpenRegister = () => {
        setOpen(true);
    };
    const handleClickOpenLogin = () => {
        setOpenLogin(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleCloseLogin = () => {
        setOpenLogin(false);
    };

    return (
        <React.Fragment>
            <CssBaseline />

            <AppBar position="fixed"
                style={{
                    backgroundColor: '#d2d6e2',
                    color: '#333'
                }}
            >
                <Toolbar>
                    <Grid item container xs={1}
                    // style={{border:'1px solid'}}
                    >
                        <img width="35%" src="https://ikan-assets.s3-ap-southeast-1.amazonaws.com/Ikan_Small.png" />
                        <Typography variant="h6" color="inherit" textColor="black" noWrap>
                            &nbsp;IKAN
                        </Typography>

                        {/* <ShowChart className={classes.icon} /> */}
                        {/* <Typography variant="h6" color="inherit" noWrap>
                            IKAN.ai
                        </Typography> */}
                    </Grid>
                    {/* <Grid item xs={2} /> */}
                    <Grid item xs={8}
                    // style={{ border: '1px solid' }}
                    />
                    <Grid container xs={3} justify="flex-end"
                    // style={{ border: '1px solid' }}
                    >
                        <Button variant="contained" color="primary">
                            Hire Top Talent
                        </Button>

                        <Button
                            style={{
                                backgroundColor: '#d2d6e2',
                                color: '#333'
                            }}
                            aria-controls="simple-menu" aria-haspopup="true" onClick={handleClickMenu}>
                            <FlagOutlinedIcon /> &nbsp;{selectedLanguage}
                        </Button>
                        <Menu
                            id="simple-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleCloseMenu}
                        >
                            <MenuItem onClick={() => handleCloseMenu('English')}>
                                <img height="32" width="32" src="https://www.pngitem.com/pimgs/m/356-3569961_us-flag-icon-usa-flag-round-hd-png.png" />
                                &nbsp; English</MenuItem>
                            <MenuItem onClick={() => handleCloseMenu('Indonesia')}>
                                <img height="32" width="32" src="https://cdn4.iconfinder.com/data/icons/iconset-addictive-flavour/png/flags/flag_indonesia.png" />
                                &nbsp; Indonesia
                            </MenuItem>
                        </Menu>
                    </Grid>

                </Toolbar>
            </AppBar>
            <main>

                <div className={classes.heroContent}
                    style={{
                        top: '50px', position: 'relative'
                    }}
                >
                    <Grid item container xs={12} style={{
                        backgroundColor: '#D1D6E2',
                        paddingTop: '15px',
                        paddingBottom: '15px',
                        // minHeight : '200px',
                    }}>
                        {/* <Paper className={classes.paper}> */}
                        <Grid item xs={2}></Grid>

                        <Grid item xs={4}>
                            {/* <h1 style={{ textAlign: "left" }}></h1> */}
                            <Typography variant="h2" component="h2">
                                Hire the Top 3% of Freelance Talent®
                            </Typography>
                            {/* <h3 style={{ textAlign: "left" }}>IKAN.ai is an exclusive network of the top freelance software developers, designers, finance experts, product managers, and project managers in the world. Top companies hire Toptal freelancers for their most important projects.</h3> */}

                            <Typography variant="subtitle1">
                                IKAN.ai is an exclusive network of the top freelance software developers, designers, finance experts, product managers, and project managers in the world. Top companies hire Toptal freelancers for their most important projects.
                            </Typography>

                            <Grid item xs={4} style={{ marginTop: '10px' }}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="large"

                                >
                                    Hire Top Talent
                                </Button>
                            </Grid>

                        </Grid>
                        <Grid item xs={1}></Grid>
                        <Grid item xs={3} style={{
                            // border:'1px solid'
                        }}>


                            <TagCloud
                                style={{
                                    fontFamily: 'sans-serif',
                                    fontSize: 30,
                                    fontWeight: 'bold',
                                    fontStyle: 'italic',
                                    color: () => randomColor(),
                                    padding: 5,
                                    width: '100%',
                                    height: '100%'
                                }}
                                >
                                <div style={{ fontSize: 50 }}>Web Development</div>
                                <div style={{ color: 'green' }}>Mobile Development</div>
                                <div rotate={90}>cloud Engineer</div>
                                <div>AWS</div>
                                <div>GCP</div>
                                <div>AWS</div>

                            </TagCloud>                            




                        </Grid>

                        {/* </Paper> */}
                    </Grid>
                </div>


                <div className={classes.heroContent}
                    style={{
                        top: '50px', position: 'relative'
                    }}
                >
                    <Grid item container xs={12} style={{
                        backgroundColor: '#f6f8fb',
                        color: '##455065',
                        paddingTop: '25px',
                        paddingBottom: '25px',
                        // minHeight : '200px',
                    }}>
                        <Grid item xs={12} sm={2}></Grid>
                        <Grid item xs={12} sm={2}>TRUSTED BY LEADING BRANDS AND STARTUPS</Grid>
                        <Grid item xs={12} sm={2}><Typography variant="h5">AirBnB</Typography></Grid>
                        <Grid item xs={12} sm={2}><Typography variant="h5">Dualingo</Typography></Grid>
                        <Grid item xs={12} sm={2}><Typography variant="h5">Air Asia</Typography></Grid>
                    </Grid>
                </div>

                <div className={classes.heroContent}
                    style={{
                        top: '50px', position: 'relative'
                    }}
                >
                    <Grid item container spacing={2} xs={12} style={{
                        backgroundColor: '#fcfdff',
                        color: '##455065',
                        paddingTop: '15px',
                        paddingBottom: '15px',
                        // border: '1px solid #ccc',
                    }}>
                        <Grid item xs={12} sm={2}></Grid>
                        {cards.map(card => (
                            <Grid item key={card} xs={12} sm={2} md={2}>
                                <Card className={classes.root}>
                                    <CardActionArea>
                                        <CardMedia
                                            component="img"
                                            alt="Contemplative Reptile"
                                            height="140"
                                            image={card.image}
                                            title="Contemplative Reptile"
                                        />
                                        <CardContent>
                                            <Typography gutterBottom variant="h5" component="h2">
                                                {card.title}
                                        </Typography>
                                            <Typography variant="body2" color="textSecondary" component="p">
                                                Looking for a professional web developer or a web company? Wanting to get web developer quotes? As businesses shift to the Internet.
                                      </Typography>
                                        </CardContent>
                                    </CardActionArea>
                                    <CardActions>
                                        <Button size="small" color="primary">
                                            Share
                                    </Button>
                                        <Button size="small" color="primary">
                                            Learn More
                                    </Button>
                                    </CardActions>
                                </Card>
                            </Grid>
                        ))}                        
                        
                    </Grid>
                </div>


                <div className={classes.heroContent}
                    style={{
                        top: '50px', position: 'relative'
                    }}
                >
                    <Grid item container spacing={2} xs={12} style={{
                        backgroundColor: '#fcfdff',
                        color: '##455065',
                        paddingTop: '15px',
                        paddingBottom: '15px',
                        // border: '1px solid #ccc',
                    }}>
                        <Grid item xs={12} sm={2}></Grid>
                        {cards.map((card,i) => (
                            <Grid item key={i} xs={12} sm={3} md={2}>
                                <Card className={classes.root} variant="outlined"
                                    style={{
                                        // backgroundColor: '#ebeced',
                                        color: '##455065',
                                        paddingTop: '15px',
                                        paddingBottom: '15px',
                                        // border: '1px solid #ccc',
                                    }}
                                >
                                    <CardContent>
                                        <Typography className={classes.title} color="textSecondary" gutterBottom>
                                            CATEGORY {i+1}
                                        </Typography>
                                        <Typography variant="subtitle1" component="p">
                                            {card.description}
                                        </Typography>
                                    </CardContent>
                                    <CardActions>
                                        <Button size="small">Learn More</Button>
                                    </CardActions>
                                </Card>
                            </Grid>
                        ))}

                    </Grid>
                </div>
            </main>
            {/* Footer */}
            <footer className={classes.footer} style={{
                marginTop : '50px'
            }}>
                <Typography variant="h6" align="center" gutterBottom>
                    IKAN.ai
                </Typography>
                {/* <Typography
                                variant="subtitle1"
                                align="center"
                                color="textSecondary"
                                component="p"
                            >
                                Something here to give the footer a purpose!
                    </Typography> */}

                {/* <Box component="div">
                    X
                </Box> */}

                <Grid container item lg="12" xs="12">
                    <Grid lg="4" xs="12" />
                    <Grid lg="4" xs="12">
                        <strong>Company</strong><br />
                        About Us <br />
                        Help Center <br />
                        Terms and Conditions <br />
                        Join Us <br />
                        Privacy <br />
                        Blog <br />
                    </Grid>
                    <Grid lg="4" xs="12">
                        <strong>Info</strong><br />
                        hallo@ikan.ai <br />
                        blog.ikan.ai <br />
                    </Grid>
                    <Grid lg="4" xs="12" />
                </Grid>

                <Copyright />
            </footer>
            {/* End footer */}
        </React.Fragment>
    );
}
